import { agent } from './api.service'
import { Cookies as ReactCookies } from 'react-cookie'

const cookies = new ReactCookies()
const cookie = cookies.get('iatseToken')

const endpoint = 'iatse_members_services/Memberships'
const naiveEndpoint = 'iatse_members_services'

// ▼ Members ▼

export async function getMembers() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}?$top=100`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getMemberByParams(
  params: object,
  top: number = 500,
  skip: number = 0,
  noLimit: boolean = false,
  sort: { [key: string]: any } = {}
) {
  // const URL = noLimit
  //   ? `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipSearch`
  //   : `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipSearch?${Object.keys(sort)?.length ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}`: ''}`
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipSearch`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getMemberByMembershipID(id: string) {
  const body = {
    craftID: null,
    memberID: id,
    firstName: null,
    surName: null,
    dob: null,
    loAdmitDtSt: null,
    loAdmitDtEd: null,
    iaAdmitDtSt: null,
    iaAdmitDtEd: null,
    statusCode: null,
    email: null,
    address: null,
    phoneNumber: null,
    startRowIndex: 0,
    maximumRows: 500,
  }
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipSearch`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function searchMember(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/ViewMember/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createMember(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipCreate`
  let response
  await agent
    .post(URL)
    .send({ ...data, dob: data.dob === '' ? null : data.dob })
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateMember(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipUpdate`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateMemberILNumber(membershipId, iL_Number) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/UpdateMemberILNumber`
  let response
  await agent
    .put(URL)
    .send({ membershipId: membershipId, iL_Number: iL_Number })
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateMemberMismatchedName(membershipId, mismatchedName) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/UpdateMemberMismatchedName`
  let response
  await agent
    .put(URL)
    .send({ membershipId: membershipId, mismatchedName: mismatchedName })
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteMember(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipDelete/${data.id}`
  let response
  await agent
    .delete(URL)
    // .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Road card numbers ▼

export const getRoadCardsByMembershipId = async (membershipId: number) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/${membershipId}?$expand=roadCards`
  return await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => ({ ...res, data: res?.body }))
    .catch((error: any) => ({ ...error.response, data: error.response?.body }))
}

export async function createRoadCard(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/RoadCardCreate`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateRoadCard(roadcard_id: number, status_id: number) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/RoadCardUpdateStatus`
  let response
  await agent
    .post(URL)
    .send({
      roadcard_id,
      status_id,
    })
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deletRoadCard(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/RoadCardDelete/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Member transfer ▼

export async function updateMemberTransfer(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/MemberTransfers`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Contract history ▼

export async function getContractHistory(
  params: { [key: string]: any },
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetMemberContractHistory?membershipid=${params.id}`
    : `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetMemberContractHistory?&membershipid=${params.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

// ▼ Audit log report ▼

export async function runAuditLog(params: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetAuditLogs?membershipId=${params.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

// ▼ Status history ▼

export async function getStatusHistory(params: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetMemberStatusHistory/${params.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function updateStatusHistory(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/UpdateMemberStatusHistory`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteStatusHistory(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/DeleteMemberStatusHistory/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Local admit history ▼

export async function getLocalAdmitHistory(params: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetLocalAdmitHistory/${params.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function updateLocalAdmitHistory(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/UpdateLocalAdmitHistory`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteLocalAdmitHistory(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/DeleteLocalAdmitHistory/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Membership events ▼

export async function getMembershipEvents() {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function insertMembershipEvent(event: { [key: string]: any }) {
  const body = {
    membershipID: parseInt(event.membershipID),
    memberEventID: parseInt(event.memberEventID) || 1,
    eventDate: event.eventDate,
    location: event.location,
    courseTitle: '',
    courseDate: null,
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents/queries/Create`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Group member ▼

export async function getMemberGroups() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetMemberGroups`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createMemberGroup(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/InsertMemberGroup`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateMemberGroup(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/UpdateMemberGroup`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteMemberGroup(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/DeleteMemberGroup/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getGroupMember(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetGroupMembers/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function deleteGroupMember(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/DeleteGroupMember/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function insertGroupMember(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/InsertGroupMember`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getSpecialMemberGroups() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetSpecialGroups`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getSpecialGroupMember(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetSpecialGroupMembers/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function deleteSpecialGroupMember(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/DeleteSpecialGroupMember/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function insertSpecialGroupMember(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/InsertSpecialGroupMember`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchMemberForGrouping(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/SearchMembersForGrouping`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function runtOutstandingStampReport() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/GetOutstandingStampReport`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

// ▼ SECTION ▼
export async function updateMembershipEvent(event: any) {
  const body = {
    membershipID: parseInt(event.membershipID),
    memberEventID: parseInt(event.memberEventID) || 1,
    eventDate: event.eventDate,
    location: event.location,
    courseTitle: '',
    courseDate: null,
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents/queries/Update`
  let response
  await agent
    .put(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getMembershipEventsByMemberID(memberID: any) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents/queries/Read/${memberID}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function createMembershipEvent(memberID: any, event: any) {
  const body = {
    membershipID: parseInt(memberID),
    memberEventID: parseInt(event.memberEventID),
    eventDate: event.eventDate,
    location: event.location,
    courseTitle: '',
    courseDate: null,
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents/queries/Read/${memberID}`
  let response
  await agent
    .get(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOfficerEmail(memberID: string) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipOfficerPortals?$filter=memberId eq ${memberID}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function registerOfficerPortalEmail(memberID: any, email: any) {
  const body = {
    memberID: parseInt(memberID),
    portalEmail: email,
    link: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/Memberships/queries/RegisterOfficerPortalEmail`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function unregisterOfficerPortalEmail(memberID: any, email: any) {
  const body = {
    memberID: parseInt(memberID),
    portalEmail: email,
    link: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/Memberships/queries/DeleteOfficerPortalEmail/${memberID}`
  let response
  await agent
    .del(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteMembershipEvent(eventID: any) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/MembershipEvents/queries/Delete/${eventID}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Member Actions ▼

export async function getMemberActions(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/MemberAction/queries/MemberActionSearch/${data.id}`
  let response
  await agent
    .get(URL)
    // .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createMemberAction(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/MemberAction`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function updateMemberAction(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/MemberAction`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function deleteMemberAction(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/MemberAction/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function validateRetired65(memberId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_membershipapplications_Services/MembershipApplication/ValidateRetired65/${memberId}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function retiredProcess(data: any, localAdminEmail: string, member: any, applicationTypeId: number) {
  const body = {
    localNum: member.localNum,
    firstName: member.firstName,
    middleName: '',
    email: data.email,
    surName: member.surname,
    cellPhone: member.cellPhone,
    localAdminEmail: localAdminEmail,
    localSecretaryEmail: data.localSecretaryEmail,
    localPresidentEmail: data.localPresidentEmail,
    localPresidentSignsLast: true,
    ssn: member.idString,
    membershipId: member.membershipID,
    applicationTypeId: applicationTypeId,
    languageId: 1,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_membershipapplications_Services/MembershipApplication/RetiredProcess`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function validateRetired75(memberId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_membershipapplications_Services/MembershipApplication/ValidateRetired75/${memberId}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function validateAddress({ address1, address2, city, state, postalZip, country }) {
  const body = {
    address1: address1 || '',
    address2: address2 || '',
    city: city || '',
    state: state || '',
    postalZip: postalZip || '',
    country: country || '',
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/Memberships/addressValidation`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getSpecialMembers(body: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/specialMembersSearch`
  return await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => ({ ...res, data: res?.body }))
    .catch((error: any) => ({ ...error.response, data: error.response?.body }))
}

export async function getSpecialMemberOrderHistory(memberId: string) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/Memberships/queries/SpecialMemberOrderHistory/${memberId}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getMemberEmail(body: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/GetMemberEmail`
  return await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => ({ ...res, data: res?.body }))
    .catch((error: any) => ({ ...error.response, data: error.response?.body }))
}

export async function getFriendlyVersion(memberId) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_members_services/Memberships/PrintMember/${memberId}`
  let response
  await agent
    .get(URL)
    .responseType('blob')
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Member Address File ▼

export async function getMemberAddressFile() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/GetMemberExportFileList`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => { response = { ...res, data: res?.body } })
    .catch((error: any) => { response = { ...error.response, data: error.response?.body } })
  return response
}

export async function generateMemberExportFile() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/GenerateMemberExportFile`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => { response = { ...res, data: res?.body } })
    .catch((error: any) => { response = { ...error.response, data: error.response?.body } })
  return response
}

export async function NoBulletinFlipUtility() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/NoBulletinFlipUtlity`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => { response = { ...res, data: res?.body } })
    .catch((error: any) => { response = { ...error.response, data: error.response?.body } })
  return response
}

export async function deleteMemberExportFile(data: any) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/DeleteMemberExportFileList`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => { response = { ...res, data: res?.body } })
    .catch((error: any) => { response = { ...error.response, data: error.response?.body } })
  return response
}

export async function downloadMemberExportFile(data: any) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/DownloadMemberExportFile`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => { response = { ...res, data: res?.body } })
    .catch((error: any) => { response = { ...error.response, data: error.response?.body } })
  return response
}