import React from 'react'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'
import { AuthConsumer } from '../auth/Auth'

export function AuthCheck({ children }: any) {
  const isBrowser = typeof window !== 'undefined'
  const [cookies] = useCookies()

  return (
    <AuthConsumer>
      {(context) =>
        context.authenticated || (cookies.iatseToken && cookies.iatseToken !== null)
          ? children
          : isBrowser && navigate('/login')
      }
    </AuthConsumer>
  )
}
