import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import { AuthContext } from '../../context/auth/Auth'
import { Flex, Heading, ModalContent, ModalOverlay, Text, Modal, Spinner } from '@chakra-ui/react'
//import { MdLock } from 'react-icons/md'

import { MdLock } from '@react-icons/all-files/md/MdLock'

import { searchMember } from '../../services/members.service'
import { getLocalByParams, searchLocal } from '../../services/locals.service'
import { RBAC_ROLES } from '../../utils/RBAC/constants'
import { getLocalNumberByOrderID } from '../../services/orders.service'

export function CanAccessRecord({ location, children }: { location: any; children: any }) {
  const [isChecking, setIsChecking] = useState(true)
  const [local, setLocal] = useState('')
  const [userLocals, setUserLocals] = useState([])

  const auth = useContext<any>(AuthContext)
  const user = auth.user
  const { pathname = '', search = '' } = location

  useEffect(() => {
    if (pageIsLocalBased) {
      setIsChecking(true)
      fetchInfo()
    }
  }, [location.pathname])

  const fetchInfo = async () => {
    // PART ONE - GET USER'S LOCAL(S)
    if (auth?.user?.appRoles?.includes(RBAC_ROLES.DISTRICT)) {
      const districtsRequests = auth.user.districts.map(async ({ districtID }) => {
        const res = await getLocalByParams({ districtID })
        if (res.error) return {}
        return res.data
      })
      const districtsResponses = await Promise.all(districtsRequests)
      const locals = districtsResponses.reduce((a, c) => [...c, ...a], []).map((i) => i.localNum)
      setUserLocals(locals)
    } else {
      const locals = user.locals.map((i) => i.local.localNum)
      setUserLocals(locals)
    }
    // PART TWO - DETERMINE LOCAL FOR THE CURRENT PAGE
    if (pathname.startsWith('/members') || pathname.startsWith('/v2/members')) {
      const memberId = pathname.startsWith('/v2/members') ? pathname.split('/')[3] : pathname.split('/')[2]
      const memberResponse = await searchMember({ id: memberId })
      if (memberResponse.error) return ''
      const l = memberResponse.data[0].localNum
      setLocal(l)
      setIsChecking(false)
    } else if (pathname.startsWith('/locals') || pathname.startsWith('/v2/locals')) {
      const localId = pathname.startsWith('/v2/locals') ? pathname.split('/')[3] : pathname.split('/')[2]
      const localResponse = await searchLocal({ localID: localId })
      if (localResponse.error) return ''
      const l = localResponse.data[0].localNum
      setLocal(l)
      setIsChecking(false)
    } else if (pathname.startsWith('/quarterly-reports') || pathname.startsWith('/v2/quarterly-reports')) {
      const reportId = pathname.startsWith('/v2/quarterly-reports') ? pathname.split('/')[3] : pathname.split('/')[2]
      const l = reportId.split('-')[1]
      setLocal(l)
      setIsChecking(false)
    } else if (pathname.startsWith('/orders') || pathname.startsWith('/v2/orders')) {
      const { order } = queryString.parse(search)
      const orderSearchResponse = await getLocalNumberByOrderID(order)
      if (orderSearchResponse.error) return ''
      const l = orderSearchResponse.data[0].localNum
      setLocal(l)
      setIsChecking(false)
    }
  }

  const pageIsLocalBased =
    (pathname.startsWith('/locals') ||
      pathname.startsWith('/v2/locals') ||
      pathname.startsWith('/members') ||
      pathname.startsWith('/v2/members') ||
      pathname.startsWith('/quarterly-reports') ||
      pathname.startsWith('/v2/quarterly-reports') ||
      pathname.startsWith('/orders') ||
      pathname.startsWith('/v2/orders')) &&
    (pathname.includes('view') || pathname.includes('edit'))

  if (
    !pageIsLocalBased ||
    (!user.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) && !user.appRoles?.includes(RBAC_ROLES.DISTRICT))
  ) {
    return children
  }

  return isChecking ? (
    <Modal isOpen={true} size="full">
      <ModalOverlay style={{ pointerEvents: 'none' }} />
      <ModalContent
        w="100%"
        h="100%"
        background="transparent"
        boxShadow="none"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="gray.500" size="xl" />
      </ModalContent>
    </Modal>
  ) : userLocals.includes(local) ? (
    children
  ) : (
    <Flex h="50vh" flexDirection="column" justifyContent="center" alignItems="center">
      <Flex justifyContent="center">
        <MdLock color="#CBD5E0" fontSize="6rem" />
      </Flex>
      <Heading textAlign="center" mt="3">
        Access Denied
      </Heading>
      <Text textAlign="center" mt="3" fontSize="xl">
        You are not authorized to access this page.
      </Text>
    </Flex>
  )
}
