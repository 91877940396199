import { navigate } from 'gatsby'
import { AuthLogin, getLoggedRoles } from '../../services/login.service'

import { saveState } from '../../storage/storage'
import { getResources } from '../../utils/rolesHelpers'
import { fakePermissions } from '../auth/fakePermissions'
import { getLocalByParams } from '../../services/locals.service'

/**
 * @function initiateLogin - Function to initiate the login process
 * @param data - The user's data with email and password
 * @param device - The device's data with type, ip and uuid
 * @param setState - hook to set the Auth context state
 * @param setCookie - hook to set the cookies
 * @param setError - hook to set the form error
 * @param logout - function to log the user out
 * @param removeCookie - hook to remove cookie
 */

export async function initiateLogin(
  data: { [key: string]: any },
  device: { [key: string]: any },
  setState: Function,
  setCookie: Function,
  setError: Function,
  logout: Function,
  removeCookie: Function
) {
  let twoFactorRedirect = false
  const authBody = {
    UserName: data.email,
    Password: data.password,
    TwoFactorAuthentication: {
      DeviceType: device.deviceType,
      DeviceCode: device.deviceCode,
      IPAddress: device.IPAddress,
      RememberMachine: data.rememberMe,
      IsRequiredToValidate: true,
    },
  }

  await AuthLogin(authBody)
    .then(async (res: any) => {
      if (res.status === 200 && res.body !== null && res.body.errorMessage === null) {
        if (res.body.bearerToken === null) {
          setState((prevState: any) => ({ ...prevState, user: { ...res?.body, rememberMe: data.rememberMe } }))
          twoFactorRedirect = true
          return
        }
        const rolesResponse = await getLoggedRoles(res.body.userID, res.body.bearerToken)
        const roleNames = rolesResponse?.body?.appUserRoles.map((appUserRole) => appUserRole.role.name)
        const rolesWithPermissions = rolesResponse?.body?.appUserRoles
        const userResources = getResources({ rolesWithPermissions })
        let newLocals = []
        if (rolesResponse?.body?.appUserDistricts) {
          const districtsRequests = rolesResponse?.body?.appUserDistricts.map(async ({ districtID }) => {
            const res = await getLocalByParams({ districtID })
            if (res.error) return {}
            return res.data
          })
          const districtsResponses = await Promise.all(districtsRequests)
          newLocals = districtsResponses.reduce((a, c) => [...c, ...a], []).map((i) => i.localNum)
        }
        //* succes
        setState((prevState: any) => ({ ...prevState, user: { ...res?.body, rememberMe: data.rememberMe } }))
        if (
          (res.body.twoFactorAuthentication !== null && res.body.twoFactorAuthentication.isRequiredToValidate) ||
          res.body.bearerToken === null
        ) {
          //? Get token
          twoFactorRedirect = true
        } else {
          const iatseUserData = JSON.stringify({
            userID: res.body.userID,
            userName: res.body.userName,
            email: res.body.email,
            isActive: res.body.isActive,
            firstName: res.body.firstName,
            middleName: res.body.middleName,
            lastName: res.body.lastName,
            surname: res.body.surname,
            createdOn: res.body.createdOn,
            appUserRoles: rolesWithPermissions,
            appRoles: roleNames,
            permissions: [...userResources, ...fakePermissions],
            locals: rolesResponse?.body?.appUserLocals,
            districts: rolesResponse?.body?.appUserDistricts,
            districtsLocals: newLocals,
          })
          await setCookie('iatseToken', res.body.bearerToken, { maxAge: 86400, path: '/' }) // 3600 x hour
          saveState(iatseUserData) // at least 15 kb, cookie limit is 4kb
          window.location.href = [
            'https://iauat-be.preview.winmill.com/',
            'https://iatse-finance-be.winmill.net/',
          ].includes(process.env.REACT_APP_API_URL)
            ? '/v2'
            : '/'
        }
      }

      if (res.status === 500) {
        //* wrong email
        setError(
          'email',
          { type: 'focus', message: 'There is no registered account with this email' },
          { shouldFocus: true }
        )
      }
      if (res.status === 204) {
        //* wrong password
        setError('password', { type: 'focus', message: 'Incorrect password' }, { shouldFocus: true })
      }

      if (res.body.errorMessage !== null) {
        //* Unhandle error
        setError(
          'email',
          {
            type: 'focus',
            message: res?.body?.errorMessage || 'An unexpected error has occurred, please contact support',
          },
          { shouldFocus: true }
        )
        console.warn('Login error: ', res.body.errorMessage)
      }
    })
    .catch((err: any) => console.warn('Login error: ', err))

  return twoFactorRedirect
}
