import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brand: {
      400: '#FFF192',
      500: '#c5a459',
      600: '#aa8d4c',
    },
    blue: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        fontFamily: 'AvenirNextLTpro',
      },
      label: {
        fontWeight: '700 !important',
      },
      input: {
        background: 'white !important',
        fontWeight: '700',
      },
      select: {
        background: 'white !important',
        fontWeight: '700',
      },
      option: {
        color: 'rgba(0, 0, 0, 0.80) !important',
      },
      'select:invalid': {
        color: 'rgba(0, 0, 0, 0.36) !important',
      },
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.36)',
      },
      '.rdt_TableHeader > div': {
        fontWeight: '700 !important',
        textTransform: 'uppercase',
      },
      '.react-datepicker__close-icon::after': {
        background: 'rgba(0, 0, 0, 0.80)',
        fontSize: '15px',
      },
      '.react-datepicker__day--selected': {
        background: 'rgba(0, 0, 0, 0.80)',
      },
      '.react-datepicker__day--keyboard-selected': {
        background: '#CBD5E0',
      },
      '.react-datepicker-popper': {
        zIndex: 99999,
      },
      '.react-datepicker__close-icon--disabled': {
        display: 'none',
      },
      '.react-datepicker__close-icon': {
        zIndex: 1,
      },
    }),
  },
})

export default theme
