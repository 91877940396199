import React, { useContext } from 'react'
import { AuthContext } from '../../context/auth/Auth'
import { Flex, Heading, Text } from '@chakra-ui/react'
//import { MdLock } from 'react-icons/md'

import { MdLock } from '@react-icons/all-files/md/MdLock'

import { convertPathIntoPermission } from '../../utils/RBAC/pathToPermission'

export function CanAccessModule({ location, children }: { location: any; children: any }) {
  const { user } = useContext(AuthContext)
  const { pathname = '', search = '' } = location
  const permission = convertPathIntoPermission(pathname, search, user)

  if (!user.permissions.length || !user.appRoles.length) return null

  if (
    pathname === '' ||
    pathname === '/' ||
    pathname === '/v2' ||
    pathname === '/v2/' ||
    user.appRoles?.includes('SuperAdmin') ||
    user.permissions?.includes(permission)
  ) {
    return children
  } else {
    return (
      <Flex h="50vh" flexDirection="column" justifyContent="center" alignItems="center">
        <Flex justifyContent="center">
          <MdLock color="#CBD5E0" fontSize="6rem" />
        </Flex>
        <Heading textAlign="center" mt="3">
          Access Denied
        </Heading>
        <Text textAlign="center" mt="3" fontSize="xl">
          You are not authorized to access this page.
        </Text>
      </Flex>
    )
  }
}
