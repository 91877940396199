import { navigate } from 'gatsby'
import { Validate2FAToken, Resend2FAToken, getLoggedRoles } from '../../services/login.service'
import * as decode from 'jwt-decode'

import { fakePermissions } from '../auth/fakePermissions'
import { getResources } from '../../utils/rolesHelpers'
import { saveState } from '../../storage/storage'

/**
 * @function validate2FAToken - Function to initiate the login process
 * @param data - The user's data with email and password
 * @param device - The device's data with type, ip and uuid
 * @param setState - hook to set the Auth context state
 * @param setCookie - hook to set the cookies
 * @param setError - hook to set the form error
 */

export async function validate2FAToken(
  data: { [key: string]: any },
  device: { [key: string]: any },
  setState: Function,
  setCookie: Function,
  setError: Function
) {
  const twoFABody = {
    UserID: data.user.userID,
    Email: data.user.email,
    UserName: data.user.userName,
    TwoFactorAuthentication: {
      MFATokenID: data.user.twoFactorAuthentication.mfaTokenID,
      TwoFactorCode: data.twoFactorAuth,
      DeviceType: device.deviceType,
      DeviceCode: device.deviceCode,
      IPAddress: device.IPAddress,
      RememberMachine: data.user.rememberMe,
      IsRequiredToValidate: false,
    },
  }

  const userData = {
    userID: data.user.userID,
    userName: data.user.userName,
    email: data.user.email,
    isActive: data.user.isActive,
    firstName: data.user.firstName,
    middleName: data.user.middleName,
    lastName: data.user.lastName,
    surname: data.user.surname,
    createdOn: data.user.createdOn,
    appUserRoles: data.user.appUserRoles,
  }

  await Validate2FAToken(twoFABody)
    .then(async (res: any) => {
      if (res.status === 200 && res.body !== null && res.body.errorMessage === null && res.body.bearerToken !== null) {
        //* succes
        setState((prevState: any) => ({ ...prevState, user: { ...res?.body } }))
        setCookie('iatseToken', res.body.bearerToken, { maxAge: 86400, path: '/' }) // 3600 x hour
        setCookie('iatseUserData', userData, { maxAge: 86400, path: '/' })

        const rolesResponse = await getLoggedRoles(res.body.userID, res.body.bearerToken)
        const roleNames = rolesResponse?.body?.appUserRoles.map((appUserRole) => appUserRole.role.name)
        const rolesWithPermissions = rolesResponse?.body?.appUserRoles
        const userResources = getResources({ rolesWithPermissions })
        const iatseUserData = JSON.stringify({
          userID: data.user.userID,
          userName: data.user.userName,
          email: data.user.email,
          isActive: data.user.isActive,
          firstName: data.user.firstName,
          middleName: data.user.middleName,
          lastName: data.user.lastName,
          surname: data.user.surname,
          createdOn: data.user.createdOn,
          appUserRoles: rolesWithPermissions,
          appRoles: roleNames,
          permissions: [...userResources, ...fakePermissions],
          locals: rolesResponse?.body?.appUserLocals,
        })
        saveState(iatseUserData) // at least 15 kb, cookie limit is 4kb
        window.location.href = [
          'https://iauat-be.preview.winmill.com/',
          'https://iatse-finance-be.winmill.net/',
        ].includes(process.env.REACT_APP_API_URL)
          ? '/v2'
          : '/'
      }

      if (res.body.errorMessage !== null || res.status === 500) {
        //* Unhandle error
        if (res.body.errorMessage === 'Invalid credentials') {
          setError(
            'twoFactorAuth',
            { type: 'focus', message: 'This code is incorrect or has already expired' },
            { shouldFocus: true }
          )
        } else {
          setError(
            'email',
            { type: 'focus', message: 'An unexpected error has occurred, please contact support' },
            { shouldFocus: true }
          )
          console.warn('Login error: ', res.body.errorMessage)
        }
      }
    })
    .catch((err: any) => console.warn('Login error: ', err))
}

/**
 * @function resend2FAToken - Function to initiate the login process
 * @param data - The user's data with email and password
 * @param device - The device's data with type, ip and uuid
 * @param setState - hook to set the Auth context state
 * @param setCookie - hook to set the cookies
 * @param setError - hook to set the form error
 */

export async function resend2FAToken(
  data: { [key: string]: any },
  device: { [key: string]: any },
  setError: Function,
  setCookie: Function
) {
  const twoFABody = {
    UserID: data.user.userID,
    Email: data.user.email,
    UserName: data.user.userName,
    TwoFactorAuthentication: {
      DeviceType: device.deviceType,
      DeviceCode: device.deviceCode,
      IPAddress: device.IPAddress,
      RememberMachine: data.user.rememberMe,
      IsRequiredToValidate: false,
    },
  }

  await Resend2FAToken(twoFABody)
    .then((res: any) => {
      if (res.status === 200 || (res.body !== null && res.body.errorMessage === null)) {
        //* succes
        return true
      }

      if (res.body.errorMessage !== null || res.status === 500) {
        //* Unhandle error
        setError(
          'email',
          { type: 'focus', message: 'An unexpected error has occurred, please contact support' },
          { shouldFocus: true }
        )
        console.warn('Login error: ', res.body.errorMessage)
      }
    })
    .catch((err: any) => console.warn('Login error: ', err))
}
