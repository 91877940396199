import { set, get } from 'idb-keyval'

const STORAGE_KEY = 'appState'

export const saveState = async (state: any): Promise<void> => {
  try {
    await set(STORAGE_KEY, state)
  } catch (error) {
    console.error('Error saving state to IndexedDB:', error)
  }
}

export const loadState = async (): Promise<any | null> => {
  try {
    const state = await get(STORAGE_KEY)
    return state
  } catch (error) {
    console.error('Error loading state from IndexedDB:', error)
    return null
  }
}
