import React from 'react'
import { AuthContext } from '../context/auth/Auth'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
} from '@chakra-ui/react'

//import { MdMenuOpen, MdMenu } from 'react-icons/md'
// import { FiHelpCircle, FiSearch, FiSettings, FiLogOut } from 'react-icons/fi'
// import { AiFillHome } from 'react-icons/ai'
// import { FaUserCircle } from 'react-icons/fa'


import { AiOutlineMenuFold } from '@react-icons/all-files/ai/AiOutlineMenuFold'
import { MdMenu } from '@react-icons/all-files/md/MdMenu'
import { FiHelpCircle } from '@react-icons/all-files/fi/FiHelpCircle'
import { FiSearch } from '@react-icons/all-files/fi/FiSearch'
import { FiSettings } from '@react-icons/all-files/fi/FiSettings'
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut'
import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { FaUserCircle } from '@react-icons/all-files/fa/FaUserCircle'

import { Link, navigate } from 'gatsby'
import { CanPerform } from './Hoc/CanPerform'
import { RBAC_PERMISSIONS } from '../utils/RBAC/constants'

export function Header({ toggleSidebar, sidebarOpen, location, sidebarMobileOpen, toggleSidebarMobile }: any) {
  const isDesktop = useBreakpointValue({ base: false, md: true, lg: true })
  const breadcrumbs = location.pathname.replace('/v2', '').slice(1).replace(/\/+$/, '').split('/')

  const queryClient = useQueryClient()
  const auth = React.useContext<any>(AuthContext)

  const sitemap = {
    admin: 'disabled',
    update: 'disabled',
    roles: `/admin/roles/search`,
    users: `/admin/users/search`,
    locals: `/locals/search`,
    officer: `/locals/${breadcrumbs[1]}/officer-maintenance/view`,
    'officer-maintenance': `/locals/${breadcrumbs[1]}/officer-maintenance/view`,
    committees: `/committees/view?region=US`,
    canada: 'disabled',
    usa: 'disabled',
    'member-card-maintenance': `/member-card-maintenance/search`,
    members: `/members/search`,
    'member-group-maintenance': `/members/member-group-maintenance`,
    shows: `/shows/search`,
    'quarterly-reports': `/quarterly-reports`,
    orders: `/orders/search`,
    items: `/orders/items/search`,
    'local-crafts': `/orders/items/local-crafts/search`,
    stamp: `/orders/stamps`,
    stamps: `/orders/stamps`,
    transaction: `/orders/search`,
    'pink-contracts': `/shows/${breadcrumbs[1]}/pink-contracts/search`,
    'pink-contract-positions': `/shows/pink-contract-positions/search`,
    companies: `/companies/search`,
    agreements: `/agreements`,
    grievances: `/grievances`,
    contacts: `/contacts`,
    locations: `/locations/search`,
    'agreement-types': `/agreement-types/search`,
    'contract-provisions': null,
    'contract-reports': 'disabled',
    'forms-and-booklets': `/forms-and-booklets/search`,
    'address-upload': null,
    'general-managers': `/general-managers`,
    reports: 'disabled',
    others: null,
    comps: `/others/comps/search`,
    usps: `/others/usps/upload`,
    process: `/others/usps/process/process`,
    'process-registration-import': `/others/process-registration-import/search`,
    'template-management': `/template-management`,
    'convention': '/convention/search',
    'committee-maintenance': '/convention/committee-maintenance',
    'review-delegate-requests': '/convention/review-delegate-requests',
    'press-agents': null,
    'search-contracts': null,
    venues: `/venues`,
    'ttf-titles': `/ttf-titles/search`,
    organizing: `/organizing`,
    'ttf-contacts': `/ttf-contacts/search`,
    'contact-ttf-titles': `/ttf-contacts/${breadcrumbs[1]}/contact-ttf-titles/view`,
    'event-contacts': `/event-contacts`,
    'member-events': `/member-events/search`,
    'safety-app': `/safety-app/main-menu-items`,
    'audit-log': null,
    'visa-tracking': '/visa-tracking/search',
    beneficiaries: '/visa-tracking/beneficiaries/search',
    attorneys: '/visa-tracking/attorneys',
    engagements: '/visa-tracking/engagements/search',
    'filing-agencies': '/visa-tracking/filing-agencies/search',
    'ins-agencies': '/visa-tracking/ins-agencies/search',
    petitioners: '/visa-tracking/petitioners/search',
    'visa-engagement-report': '/visa-tracking/visa-engagement-report',
    'traveling-members-reports': 'disabled',
    'bulletin-sign-up' : '/bulletin-sign-up'
  }

  return (
    <Box as="section" position="sticky" top="0" bg="white" zIndex="1000" h="88px">
      <Box as="nav">
        <Box p="6">
          <Flex justify="space-between" alignItems={['center', 'center', 'flex-start']}>
            <HStack spacing={[0, 0, 4]}>
              <IconButton
                size="sm"
                display={['none', 'none', 'flex']}
                colorScheme="brand"
                onClick={() => toggleSidebar()}
                variant="solid"
                icon={sidebarOpen ? <AiOutlineMenuFold fontSize="1.25rem" /> : <MdMenu fontSize="1.25rem" />}
                aria-label="Open Menu"
                id="header_actions-open-menu"
              />
              <Flex display={['flex', 'flex', 'none']} alignItems="center">
                <Box w="16">
                  <img style={{ width: '100%' }} src="/v2/images/IATSE_logo_4c.svg" />
                </Box>
                <Heading fontSize="1.05rem" letterSpacing="tight" color="blackAlpha.800" flex={1} as="h1" ml="3">
                  IATSE Member & Traveling Members Application
                </Heading>
              </Flex>
              <Box>
                <Breadcrumb display={['none', 'none', 'block']}>
                  <BreadcrumbItem>
                    <Link to="/">
                      <AiFillHome color="rgba(0, 0, 0, 0.64)" fontSize="1.25rem" />
                    </Link>
                  </BreadcrumbItem>

                  {breadcrumbs.map(
                    (item: any, i: number) =>
                      isNaN(parseInt(item)) && (
                        <BreadcrumbItem key={i}>
                          <BreadcrumbLink
                            onClick={() => navigate(sitemap[item.toLowerCase()] || '#')}
                            pointerEvents={i + 1 == breadcrumbs.length || sitemap[item] == 'disabled' ? 'none' : 'auto'}
                            fontWeight={
                              [
                                'search',
                                'view',
                                'create',
                                'edit',
                                'update',
                                'view-order',
                                'create-order',
                                'add-order',
                                'edit-order',
                                'upload',
                                'process',
                              ].includes(item.toLowerCase())
                                ? 'bold'
                                : 'normal'
                            }
                          >
                            {
                              item.toLowerCase()
                              .replaceAll('-', ' ')
                              .split(' ')
                              .map(word => 
                                ['ttf','usps','qr','il','ins'].includes(word) //All letters should be captial in breadcrumb
                                  ? word.toUpperCase() : ['a','by','to','and'].includes(word) //All letters should be lowercase in breadcrumb
                                  ? word 
                                  : word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')
                            }
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                      )
                  )}
                </Breadcrumb>
              </Box>
            </HStack>

            {isDesktop ? (
              <HStack spacing="4">
                <ButtonGroup colorScheme="blackAlpha" variant="ghost" spacing="1">
                  <Tooltip label={'Search'} openDelay={800}>
                    <CanPerform permission={RBAC_PERMISSIONS.GENERAL_SEARCH}>
                      <Link to="/search">
                        <IconButton
                          icon={<FiSearch fontSize="1.25rem" />}
                          aria-label="Search"
                          onClick={() => {}}
                          id="header_actions-search"
                        />
                      </Link>
                    </CanPerform>
                  </Tooltip>
                  <Menu>
                    <Tooltip label={'Settings'} openDelay={800}>
                      <MenuButton
                        aria-label="Settings"
                        id="header_actions-settings"
                        as={IconButton}
                        icon={<FiSettings fontSize="1.25rem" />}
                      >
                        Settings
                      </MenuButton>
                    </Tooltip>
                    <MenuList>
                      <MenuItem as={Link} to={'/user-settings/change-email'}>
                        Change Email
                      </MenuItem>
                      <MenuItem as={Link} to={'/user-settings/change-password'}>
                        Change Password
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Tooltip label={'Help'} openDelay={800}>
                    <IconButton
                      icon={<FiHelpCircle fontSize="1.25rem" />}
                      aria-label="Help Center"
                      onClick={() => window.location.href = "mailto:helpdesk@iatse.net"}
                      id="header_actions-help"
                    />
                  </Tooltip>
                  <Tooltip label={'Logout'} openDelay={800}>
                    <IconButton
                      icon={<FiLogOut fontSize="1.25rem" />}
                      aria-label="Logout"
                      onClick={() => {
                        queryClient.clear()
                        auth.actions.logout()
                      }}
                      id="header_actions-logout"
                    />
                  </Tooltip>
                  <Tooltip label={`Logged in as ${auth?.user?.firstName} ${auth?.user.lastName}`} openDelay={800}>
                    <IconButton icon={<FaUserCircle fontSize="1.25rem" />} aria-label="user" />
                  </Tooltip>
                </ButtonGroup>
              </HStack>
            ) : (
              <IconButton
                size="sm"
                colorScheme="brand"
                onClick={() => toggleSidebarMobile()}
                variant="solid"
                icon={sidebarMobileOpen ? <AiOutlineMenuFold fontSize="1.25rem" /> : <MdMenu fontSize="1.25rem" />}
                aria-label="Open Menu"
                id="header_actions-open-menu"
              />
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
