import React, { useState, useEffect, useRef } from 'react'
import { ChakraProvider, Flex, useBreakpointValue } from '@chakra-ui/react'
import { Auth } from '../context/auth/Auth'
import { AuthCheck } from '../context/autchCheck/authCheck'
import GlobalContext from '../context/global'
import { CookiesProvider, useCookies } from 'react-cookie'
import { ToastContainer } from 'react-toastify'
import { Sidebar } from './Sidebar'
import { Header } from './Header'
import theme from '../styles/customTheme'
import 'react-toastify/dist/ReactToastify.min.css'
import '../styles/fonts.css'
import 'react-datepicker/dist/react-datepicker.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CanAccessModule } from './Hoc/CanAccessModule'
import { CanAccessRecord } from './Hoc/CanAccessRecord'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 1440 * 60 * 1000, // 24 hours, same as the user session
    },
  },
})

export function Layout({ children, location }: any) {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false)

  const isDesktop = useBreakpointValue({ base: false, sm: false, md: true })
  const [cookies, setCookie] = useCookies()
  const ref = useRef()

  useEffect(() => {
    setSidebarMobileOpen(false)
  }, [location])

  useEffect(() => {
    if (ref.current) ref.current.scrollTo(0, 0)
  })

  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen)
    setCookie('iatseSidebar', !sidebarOpen)
  }

  function toggleSidebarMobile() {
    setSidebarOpen(false)
    setCookie('iatseSidebar', false)
    setSidebarMobileOpen(!sidebarMobileOpen)
  }

  useEffect(() => {
    if (typeof cookies.iatseSidebar !== 'undefined') {
      if (cookies.iatseSidebar === 'true') setSidebarOpen(true)
      if (cookies.iatseSidebar === 'false') setSidebarOpen(false)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <Auth>
          <AuthCheck>
            <ChakraProvider theme={theme}>
              <GlobalContext>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <Flex h="100vh" maxW="100vw" overflow="hidden">
                  <Sidebar sidebarOpen={isDesktop ? sidebarOpen : sidebarMobileOpen} location={location} />
                  <Flex
                    ref={ref}
                    overflowY="auto"
                    // maxW={sidebarOpen ? 'calc(100% - 20rem)' : '100%'}
                    flex={1}
                    flexDirection="column"
                    overflow="scroll"
                    maxH="100vh"
                    position="relative"
                    style={
                      ['/login', '/login/'].includes(location.pathname)
                        ? { backgroundImage: 'linear-gradient(45deg, #FFFFFF 0%, #FEBF52 100%)' }
                        : {}
                    }
                  >
                    <Header
                      sidebarOpen={sidebarOpen}
                      sidebarMobileOpen={sidebarMobileOpen}
                      toggleSidebarMobile={toggleSidebarMobile}
                      toggleSidebar={toggleSidebar}
                      location={location}
                    />
                    <CanAccessModule location={location}>
                      <CanAccessRecord location={location}>{children}</CanAccessRecord>
                    </CanAccessModule>
                  </Flex>
                </Flex>
              </GlobalContext>
            </ChakraProvider>
          </AuthCheck>
        </Auth>
      </CookiesProvider>
    </QueryClientProvider>
  )
}
