import { APP_PERMISSIONS, RBAC_FUNCTIONALITIES, RBAC_PERMISSIONS, RBAC_ROLES } from './constants'

const notContainsAnyOfRoles = (user, roles) => {
  return !user?.appRoles.some((item) => roles.includes(item))
}

export const notContainsAnyOfPermissions = (user, permissions) => {
  return !user?.[APP_PERMISSIONS]?.some((item) => permissions.includes(item))
}

const isOnlyLocalAdmin = (user) => {
  return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) && user?.appRoles?.length == 1
}

/*
    This functionality returns true when conditions are met
    and has the purpose of hiding the functionality
    f.i. if the user is a local admin, hide create member button
*/
export const rbacChecker = (user, functionality) => {
  if (user?.appRoles?.includes(RBAC_ROLES.SUPER_ADMIN)) return false
  if (!user || user?.appRoles?.length == 0) return true
  switch (functionality) {
    case RBAC_FUNCTIONALITIES.HOME_SHOW_IN_SIDEBAR:
      return (
        notContainsAnyOfPermissions(user, [
          RBAC_PERMISSIONS.HOME,
          RBAC_PERMISSIONS.MODIFY_ROLE,
          RBAC_PERMISSIONS.UPDATE_USER_INFO,
          RBAC_PERMISSIONS.CHANGE_EMAIL,
        ]) && !isOnlyLocalAdmin(user)
      )
    case RBAC_FUNCTIONALITIES.HOME_ROLES_SEARCH:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.MODIFY_ROLE])
    case RBAC_FUNCTIONALITIES.HOME_USERS_SEARCH:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.UPDATE_USER_INFO])
    case RBAC_FUNCTIONALITIES.HOME_CHANGE_EMAIL:
      return !isOnlyLocalAdmin(user)
    case RBAC_FUNCTIONALITIES.HOME_USERS_EDIT_GENERAL_INFO:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.UPDATE_USER_INFO]) || isOnlyLocalAdmin(user)
    case RBAC_FUNCTIONALITIES.MEMBER_CREATE:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.MEMBERS_CREATE]) || isOnlyLocalAdmin(user)
    case RBAC_FUNCTIONALITIES.MEMBER_EDIT_EVENTS:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.MEMBERS_EDIT_EVENTS])
    case RBAC_FUNCTIONALITIES.MEMBER_CLEAR_SEARCH:
      return (
        (user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) ||
          user?.appRoles?.includes(RBAC_ROLES.LOCAL_CLERK) ||
          user?.appRoles?.includes(RBAC_ROLES.READ_ONLY_LOCAL)) &&
        (user?.appRoles?.length == 1 ||
          (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER)))
      )
    case RBAC_FUNCTIONALITIES.MEMBER_COMMENTS:
      return (
        (user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) ||
          user?.appRoles?.includes(RBAC_ROLES.LOCAL_CLERK) ||
          user?.appRoles?.includes(RBAC_ROLES.READ_ONLY_LOCAL)) &&
        (user?.appRoles?.length == 1 ||
          (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER)))
      )
    case RBAC_FUNCTIONALITIES.MEMBER_CREATED_BY:
      return (
        (user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) ||
          user?.appRoles?.includes(RBAC_ROLES.LOCAL_CLERK) ||
          user?.appRoles?.includes(RBAC_ROLES.READ_ONLY_LOCAL)) &&
        (user?.appRoles?.length == 1 ||
          (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER)))
      )
    case RBAC_FUNCTIONALITIES.MEMBER_UPDATED_BY:
      return (
        (user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) ||
          user?.appRoles?.includes(RBAC_ROLES.LOCAL_CLERK) ||
          user?.appRoles?.includes(RBAC_ROLES.READ_ONLY_LOCAL)) &&
        (user?.appRoles?.length == 1 ||
          (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER)))
      )
    /* QUARTERLY REPORTS MODULE */
    case RBAC_FUNCTIONALITIES.QUARTERLY_REPORTS_CLEAR_SEARCH:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) && user?.appRoles?.length == 1
    case RBAC_FUNCTIONALITIES.QUARTERLY_REPORTS_SEARCH_SPECIAL_COLUMNS:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN) && user?.appRoles?.length == 1
    case RBAC_FUNCTIONALITIES.ASSIGN_ROLE:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.ASSIGN_ROLE])
    case RBAC_FUNCTIONALITIES.LOCALS_SEARCH:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.VIEW_LOCAL])
    case RBAC_FUNCTIONALITIES.LOCALS_OFFICERS_REMOVE:
      return notContainsAnyOfRoles(user, [RBAC_ROLES.IATSEAdministrator])
    case RBAC_FUNCTIONALITIES.LOCALS_OFFICERS_UPDATE:
      return notContainsAnyOfPermissions(user, [RBAC_PERMISSIONS.UPDATE_OFFICER])
    case RBAC_FUNCTIONALITIES.LOCALS_OFFICER_RANK_MODIFICATION:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_BOND_AMOUNT:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_EIN:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_DELEGATE_KIT_SENT:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_SUPPLIES_RECEIVED:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_SUPPLIES_RECEIVED_DATE:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_JURISDICTION:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_SHORT_JURISDICTION:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_CLASSES:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_DISTRICTS:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_CHARTER_DATE:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.LOCALS_DEFUNCT_DATE:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_COMMENT:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.LOCALS_CRAFT:
      return user?.appRoles?.includes(RBAC_ROLES.LOCAL_ADMIN)
    case RBAC_FUNCTIONALITIES.REPORTS_AUTOMATIC_SEARCH:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    case RBAC_FUNCTIONALITIES.REPORTS_SHOW_SEARCH_FORM:
      return (
        isOnlyLocalAdmin(user) ||
        (user?.appRoles?.length == 2 && user?.appRoles?.includes(RBAC_ROLES.IATSE_UNION_MEMBER))
      )
    default:
      return false
  }
}
