import React, { useContext } from 'react'
import { AuthContext } from '../../context/auth/Auth'

export function CanPerform({ permission, children }: { permission?: string; children: any }) {
  const { user } = useContext(AuthContext)

  if (!permission) {
    return children
  }

  if (Array.isArray(permission)) {
    if (user?.permissions.some((userPermission) => permission.includes(userPermission?.toUpperCase()))) {
      return children
    }
  } else {
    if (user?.appRoles?.includes('SuperAdmin') || user?.permissions?.includes(permission?.toUpperCase())) {
      return children
    }
  }
}
