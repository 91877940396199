import { navigate } from 'gatsby'
import { saveState } from '../../storage/storage'

/**
 * @function logout - Function to logout the user
 * @param removeCookie - hook to remove cookie
 * @param setState - hook to set Auth context state
 */

export const logout = (removeCookie: Function, setState: Function) => {
  setState({
    authenticated: false,
    message: {
      text: '',
      variant: '',
    },
    user: {
      userID: '',
      userName: '',
      email: '',
      password: '',
      isActive: true,
      firstName: '',
      middleName: '',
      lastName: '',
      surname: '',
      createdOn: '',
      bearerToken: '',
      appUserRoles: [],
      twoFactorAuthentication: {
        mfaTokenID: '',
        twoFactorCode: '',
        deviceCode: '',
        ipAddress: '0.0.0.0',
        isRequiredToValidate: true,
      },
    },
    loading: false,
  })
  removeCookie('iatseToken', { path: '/' })
  removeCookie('iatseUserData', { path: '/' })
  saveState(null)
  navigate('/login')
}
