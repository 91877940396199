import { agent } from './api.service'
import { Cookies as ReactCookies } from 'react-cookie'

const cookies = new ReactCookies()
const cookie = cookies.get('iatseToken')

const endpoint = 'iatse_rbac_services/AppUsers'

// ▼ Login ▼

export async function AuthLogin(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/Login`
  let response
  await agent
    .post(URL)
    .send(data)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getLoggedRoles(userID: string, token: string) {
  const bearerToken = cookie || token
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/${userID.toString()}`
  let response
  await agent
    .get(URL)
    .query(
      '$select=userID, userName, isActive&$expand=appUserRoles($expand=role($select=roleID,name,isActive;$expand=appRoleResources($select=resource;$expand=resource($select=resourceID,name,key,url,isActive,showInMenu)));$select=userRoleID)&$expand=appUserLocals($select=localID,isAdmin;$expand=local($select=localID,localNum,city,stateRegion))&$expand=appUserDistricts($select=districtID;$expand=district($select=DistrictID,DistrictNum))'
    )
    .set('Authorization', 'Bearer ' + bearerToken)
    .then((res) => {
      response = { ...res, data: res?.body }
    })
    .catch((error) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function ChangePassword(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/Login`
  let response
  await agent
    .post(URL)
    .send(data)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function Validate2FAToken(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/Validate2FAToken`
  let response
  await agent
    .post(URL)
    .send(data)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function Resend2FAToken(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/Resend2FAToken`
  let response
  await agent
    .post(URL)
    .send(data)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function impersonate(data: { userName: string }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/Impersonate?$expand=appUserRoles`
  let response
  await agent
    .post(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .send(data)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((err: any) => {
      response = { ...err.response, data: err.response?.body }
    })
  return response
}
