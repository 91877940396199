import * as React from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
import { agent } from '../../services/api.service'
import jwt_decode from 'jwt-decode'
import { initiateLogin } from '../functions/initiateLogin'
import { validate2FAToken, resend2FAToken } from '../functions/twoFAToken'
import { logout } from '../functions/logout'
import { loadState } from '../../storage/storage'

type Login = {
  email: string
  password: string
}

export const AuthContext = React.createContext({
  authenticated: false,
  message: {
    text: '',
    variant: '',
  },
  user: {
    userID: '',
    userName: '',
    email: '',
    password: '',
    isActive: true,
    firstName: '',
    middleName: '',
    lastName: '',
    surname: '',
    createdOn: '',
    bearerToken: '',
    appUserRoles: [],
    permissions: [],
    appRoles: [],
    locals: [],
    twoFactorAuthentication: {
      mfaTokenID: '',
      twoFactorCode: '',
      deviceCode: '',
      ipAddress: '0.0.0.0',
      isRequiredToValidate: true,
    },
  },
  loading: false,
  actions: {
    initiateLogin: (data: Login) => {},
    // adLogin: (adToken: string) => {},
    // aliasLogin: (username: string) => {},
    // aliasLogout: () => {},
    // logout: () => {},
    // resetMessageHandler: () => {},
    // resetSubmitHandler: (e: Event, username: string) => {},
    // updateUserAvatar: (avatar: string) => {},
    // adLogout: (removeCookie: any, setState: any) => {},
  },
})

export const AuthConsumer = AuthContext.Consumer

export function Auth(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies(['iatseToken', 'iatseUserData'])
  const [device, setDevice] = React.useState<any>({ deviceType: '', deviceCode: uuidv4(), IPAddress: '0.0.0.0' })
  const [state, setState] = React.useState<any>({
    authenticated: false,
    message: {
      text: '',
      variant: '',
    },
    user: {
      userID: '',
      userName: '',
      email: '',
      password: '',
      isActive: true,
      firstName: '',
      middleName: '',
      lastName: '',
      surname: '',
      createdOn: '',
      bearerToken: '',
      appUserRoles: [],
      permissions: [],
      appRoles: [],
      locals: [],
      twoFactorAuthentication: {
        mfaTokenID: '',
        twoFactorCode: '',
        deviceCode: '',
        ipAddress: '0.0.0.0',
        isRequiredToValidate: true,
      },
    },
    loading: false,
  })

  const providerValue = {
    ...state,
    setState: setState,
    actions: {
      initiateLogin: (data: Login, setError: Function) =>
        initiateLogin(data, device, setState, setCookie, setError, logout, removeCookie),
      validate2FAToken: (data: any, setError: Function) =>
        validate2FAToken({ ...data, ...state }, device, setState, setCookie, setError),
      resend2FAToken: (setError: Function) => resend2FAToken(state, device, setError, setCookie),
      logout: () => logout(removeCookie, setState),
      // aliasLogin: username => aliasLogin(username, setState),
      // aliasLogout: () => aliasLogout(setState, removeCookie),
      // updateUserAvatar: avatar => updateUserAvatar(avatar, setState),
      // resetMessageHandler: () => resetMessageHandler(setState),
      // resetSubmitHandler: (e, username) => resetSubmitHandler(e, username, setState),
    },
  }

  const retrieveUserData = async (state: any) => {
    const currentState = await loadState()
    if (!currentState) return
    const iatseUserData = JSON.parse(currentState)
    if (iatseUserData?.appUserRoles?.length > 0) {
      setState({
        ...state,
        user: {
          ...state.user,
          ...iatseUserData,
        },
      })
    }
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      agent
        .get('https://api.ipify.org')
        .then((res: Response) => setDevice({ ...device, IPAddress: res.text, deviceType: window.navigator.userAgent }))
        .catch((err: any) => console.error(err))

      if (cookies?.iatseToken && !cookies?.iatseUserData) {
        const decoded = jwt_decode(cookies.iatseToken)
        setState({
          ...state,
          user: { ...state.user, userName: decoded.DisplayName, email: decoded.Email, userID: decoded.UserID },
        })
      }

      retrieveUserData(state)
    }
  }, [])

  return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>
}

export default Auth
