import * as React from 'react'

export const AppContext = React.createContext(null)
export const Consumer = AppContext.Consumer

export function MyContext(props: any) {
  //^ Handle stored id when navigate
  const [redirectWithId, setRedirectWithId] = React.useState<any>(0)
  const handleRedirectWithId = (id: any) => {
    setRedirectWithId(id)
  }

  //^ Handle data when navigate
  const [redirectWithData, setRedirectWithData] = React.useState<object>({})
  const handleRedirectWithData = (data: any) => {
    setRedirectWithData(data)
  }

  //^ Handle active local num
  const [localNum, setLocalnum] = React.useState<any>(0)
  const handleLocalNum = (localNum: any) => {
    setLocalnum(parseInt(localNum))
  }

  //^ Handle persisting search data
  const [persistData, setPersistData] = React.useState<any>({})
  const handlePersistData = (path: string, data: Array<{ [key: string]: any }>) => {
    const lazyDataStored = 5
    if (Object.keys(persistData).length > lazyDataStored) {
      delete persistData[Object.keys(persistData)[0]]
      setPersistData((curr: { [key: string]: JSX.Element }) => {
        return { ...curr, [path]: data }
      })
    } else {
      setPersistData((curr: { [key: string]: JSX.Element }) => {
        return { ...curr, [path]: data }
      })
    }
  }

  //^ Handle selected events to suscribe in the form
  const [dataExample, setDataExample] = React.useState<any>({})
  const dataFunctionExample = (example: any) => {
    setDataExample(example)
  }

  return (
    <AppContext.Provider
      value={{
        dataExample: dataExample,
        persistData,
        redirectWithId,
        localNum,
        redirectWithData,
        actions: {
          handlePersistData,
          dataFunctionExample: dataFunctionExample,
          handleRedirectWithId: handleRedirectWithId,
          handleRedirectWithData: handleRedirectWithData,
          handleLocalNum: handleLocalNum,
        },
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default MyContext
