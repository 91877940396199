import { agent } from './api.service'
import { Cookies as ReactCookies } from 'react-cookie'

const cookies = new ReactCookies()
const cookie = cookies.get('iatseToken')

const endpoint = 'iatse_locals_services/Locals'
const naiveEndpoint = 'iatse_locals_services'
const committeesEndpoint = 'IATSE_officers_Services/Committees'

// ▼ Locals ▼

export async function getLocalByParams(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false,
  sort: { [key: string]: any } = {}
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${endpoint}/queries/Search`
    : `${process.env.REACT_APP_API_URL}${endpoint}/queries/Search?${
        Object.keys(sort)?.length ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}` : ''
      }`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchLocal(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/Search`
  let response
  await agent
    .post(URL)
    .send({ ...params, localNum: '', craftID: 0, districtID: 0, stateOrRegionID: 0 })
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createLocal(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/Create`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateLocal(data: { [key: string]: string | number | boolean }) {
  const obj = { ...data }
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === '' && key.toLowerCase().includes('date')) {
      obj[key] = null
    }
  })

  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/Update`
  let response
  await agent
    .put(URL)
    .send(obj)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteLocal(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/MembershipDelete/${data.id}`
  let response
  await agent
    .delete(URL)
    // .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchOfficers(data: { [key: string]: string | number }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/Officers/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getOfficerTitleByMembershipID(membershipID: { [key: string]: string | number }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint_officers}/GetOfficerTitleByMembershipID/${membershipID}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

// ▼ Officers Maintenance ▼

const endpoint_officers = 'IATSE_officers_Services/Officers'

export async function searchLocalOfficer(data: { [key: string]: string | number }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint_officers}/SelectOfficer/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getOfficersByParams(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${endpoint_officers}/OfficerSearch`
    : `${process.env.REACT_APP_API_URL}${endpoint_officers}/OfficerSearch`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function createLocalOfficer(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint_officers}`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateLocalOfficer(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint_officers}`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteLocalOfficer(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint_officers}`
  let response
  await agent
    .delete(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchMemberForGrouping(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${endpoint_officers}/AddExistingMemberAsOfficerSearch`
    : `${process.env.REACT_APP_API_URL}${endpoint_officers}/AddExistingMemberAsOfficerSearch`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Officers History ▼

const endpoint_officers_history = 'IATSE_officers_Services/Officers'

export async function getOfficersHistoryByParams(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${endpoint_officers_history}/GetOfficerHistory`
    : `${process.env.REACT_APP_API_URL}${endpoint_officers_history}/GetOfficerHistory`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Officers Report ▼

export async function runOfficerUpdateReport(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/LocalOfficerDataUpdateReport/queries/LocalOfficerDataUpdateReport`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Committee ▼

export async function getCommittees(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/SelectLocalCommittee/${data.id}/${data.region}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateCommitteeCheck(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/InsertLocalCommittee`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteCommitteeCheck(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/DeleteLocalCommittee/${data.id}/${data.region}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function runCommitteeReport(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/GetLocalCommitteeReport/${data.region}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function resetSuppliesSent() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/ResetSuppliesSent/`
  let response
  await agent
    .put(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function resetDelegateKitSent() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/ResetDelegateKitSent/`
  let response
  await agent
    .put(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getAllUSLocalCommittees() {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/GetAllUSLocalCommittees`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function createUSLocalCommittee(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/CreateUSLocalCommittee`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateUSLocalCommittee(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/UpdateUSLocalCommittee`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getAllCanadianLocalCommittees() {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/GetAllCanadianLocalCommittees`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function createCanadianLocalCommittee(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/CreateCanadianLocalCommittee`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateCanadianLocalCommittee(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${committeesEndpoint}/UpdateCanadianLocalCommittee`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Merge Locals ▼

const endpoint_merge_locals = 'IATSE_officers_Services/Officers'

export async function mergeLocals(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/merge`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
