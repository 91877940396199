// These are the permissions for faking RBAC
export const fakePermissions = [
  'admin:read',
  'roles:read',
  'users:read',
  'members:read',
  'orders:read',
  'locals:read',
  'shows:read',
  'member-card-maintenance:read',
  'reports:read',
  'quarterly-reports:read',
  'forms-and-booklets:read',
  'others:read',
  'companies:read',
  'venues:read',
  'template-management:read',
  'general-managers:read',
  'locations:read',
  'contacts:read',
  'agreements:read',
  'contract-provisions:read',
  'contract-reports:read',
  'grievances:read',
]
