export type AppPermission = {
  parentID: number
  ID: number
  key: string
  name?: string
  title?: string
  isActive?: boolean
  showInMenu?: boolean
  chidren?: AppPermission[]
}
/**
 * @function arrayToTree - Function that converts an array of AppPermission to a tree structure
 * @param arr - The AppPermission Array which should contain ID and parentID fields
 * @param parent - The node's parent
 */
export const arrayToTree = (arr: AppPermission[], parent = 1): AppPermission[] =>
  arr.filter((item) => item.parentID === parent).map((child) => ({ ...child, children: arrayToTree(arr, child.ID) }))

export const flattenTree = (tree) => {
  const flattened = []

  function flatten(node, parentID) {
    const flattenedNode = {
      resourceID: node.resourceID,
      name: node.name,
      parentID: parentID,
      key: node.key,
      isActive: node.isActive,
      showInMenu: node.showInMenu,
      title: node.title,
      ID: node.ID,
    }

    flattened.push(flattenedNode)

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flatten(child, node.resourceID)
      }
    }
  }

  for (const node of tree) {
    flatten(node, null)
  }

  return flattened
}

export const treatPermissions = ({ response }) => {
  let resources = response.data.map((r) => ({ ...r, title: r.name, ID: r.resourceID }))
  let allResources = []
  resources.forEach((resource) => {
    if (!allResources.map((ar) => ar.key).includes(resource.key)) {
      allResources.push(resource)
    }
  })
  return allResources
}

export const findNodeParentsByKey = (tree, nodeKey) => {
  const parents = []

  function findParents(node, targetKey, parentKeys) {
    if (node.key === targetKey) {
      parents.push(...parentKeys)
      return true
    }

    const updatedParentKeys = [...parentKeys, node.key]

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (findParents(child, targetKey, updatedParentKeys)) {
          return true
        }
      }
    }

    return false
  }

  for (const node of tree) {
    findParents(node, nodeKey, [])
  }

  return parents
}

export const filter = (array: any[], fn: Function) => {
  return array.reduce((r, o) => {
    var children = filter(o.children || [], fn)

    if (children.length) {
      // If any child matches the condition, include the parent with the matched children
      r.push(Object.assign({}, o, { children }))
    } else if (fn(o)) {
      // If only the parent matches the condition, exclude the children
      const newO = JSON.parse(JSON.stringify(o))
      delete newO.children
      r.push(Object.assign({}, newO))
    }
    return r
  }, [])
}

export const deleteObjectAndDescendantsByKeys = (data, keys) => {
  return data.reduce((result, obj) => {
    if (!keys.includes(obj.key)) {
      if (obj.children && obj.children.length > 0) {
        obj.children = deleteObjectAndDescendantsByKeys(obj.children, keys) // Recursively delete in the children array
      }
      result.push(obj)
    }
    return result
  }, [])
}

/**
 * @function filterKeepChildren - Function that allows to filter an array of AppPermission and keep their children
 * @param arr - The AppPermission Array which should contain ID and parentID fields
 * @param parent - The node's parent
 */
export const filterKeepChildren = (array: AppPermission[], fn: Function) => {
  return array.reduce((r, o) => {
    let children = filter(o.children || [], fn)
    if (children?.length > 0) {
      children = o.children
    }
    if (fn(o) || children.length) r.push(Object.assign({}, o, children.length && { children }))
    return r
  }, [])
}

export const separateRoleIDs = (array) => {
  const roleIDs = array.map((obj) => obj.roleID)
  return roleIDs.join(',')
}

export const separateUniqueRoleIDs = (array) => {
  if (!Array.isArray(array) || array.length == 0) return ''
  const roleIDs = new Set(array.map((obj) => obj.roleID))
  return Array.from(roleIDs).join(',')
}

export const convertToRolesWithRecordActions = (currentRoles, values) => {
  const oldRoles = currentRoles.split(',')
  const visibleRoles = values.localRoles.split(',')

  const rolesAdded = visibleRoles
    .filter((item) => !oldRoles.includes(item))
    .map((r) => {
      return {
        roleID: r,
        recordAction: 1,
      }
    })

  const rolesDeleted = oldRoles
    .filter((item) => !visibleRoles.includes(item))
    .map((r) => ({
      roleID: r,
      recordAction: 3,
    }))
  const appUserRoles = [...rolesAdded, ...rolesDeleted]
  return appUserRoles
}

export const getResources = (user) => {
  const appUserRoles = user?.rolesWithPermissions
  const nonParentResources: any = []
  appUserRoles.forEach((userRole: any) => {
    const roleResources = userRole?.role?.appRoleResources
    const appResources = roleResources
      .filter((resource) => {
        return resource
      })
      ?.map((r) => r?.resource?.key)

    if (appResources?.length > 0) {
      appResources.forEach((appResource) => {
        if (!nonParentResources.includes(appResource)) {
          nonParentResources.push(appResource.toUpperCase())
        }
      })
    }
  })
  return [...new Set(nonParentResources)]
}
