const React = require('react')
const Layout = require('./src/components/Layout').Layout
const publicPages = require('./src/utils/publicPages').publicPages

exports.wrapPageElement = ({ element, props }) => {
  if (publicPages.some((path) => props.location.pathname.includes(path))) {
    return <React.Fragment>{element}</React.Fragment>
  } else {
    return <Layout {...props}>{element}</Layout>
  }
}
