import React from 'react'
import { Link } from 'gatsby'
import { HStack, Icon, Text } from '@chakra-ui/react'

type NavLinkProps = {
  href: string
  icon: React.ElementType
  children: React.ReactNode
  isHighlighted: boolean
  id: string
}

export function NavLink(props: NavLinkProps) {
  const { href, icon, children, isHighlighted } = props

  return (
    <Link to={href} id={props.id}>
      <HStack
        maxW={['10rem', '10rem', 'unset']}
        borderRadius="md"
        _hover={{ bg: 'gray.50' }}
        bg={isHighlighted ? 'gray.100' : ''}
        p="2"
        spacing="3"
      >
        <Icon color="transparent" as={icon} fontSize="xl" />
        <Text color="blackAlpha.900" fontSize="sm" fontWeight={isHighlighted ? 'medium' : 'medium'}>
          {children}
        </Text>
      </HStack>
    </Link>
  )
}
